
import { Vue, Component, Watch } from 'vue-property-decorator';
import { getMenu } from '@/api/menu';
import { IMenuDetail } from '@/types';

@Component({
  name: 'ContentsIndex',
})
export default class extends Vue {
  @Watch('$route')
  private handleChangeMenu() {
    this.getMenuDetail();
  }

  mounted() {
    this.getMenuDetail();
  }

  private menu: IMenuDetail | null = null;

  private getMenuDetail() {
    getMenu(this.$route.params.menuUid).then((res: any) => {
      this.menu = res.data;
    });
  }
}
